import React, { useRef, useEffect } from "react";
import "./styles.css";

export function CategoryTabs({ categories, activeCategory, onCategoryChange }) {
  const tabsRef = useRef(null);
  const activeTabRef = useRef(null);

  const scrollToTab = (tabElement) => {
    if (tabsRef.current && tabElement) {
      const container = tabsRef.current;
      const scrollLeft =
        tabElement.offsetLeft -
        (container.clientWidth - tabElement.offsetWidth) / 2;
      container.scrollTo({ left: scrollLeft, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (activeTabRef.current) {
      scrollToTab(activeTabRef.current);
    }
  }, [activeCategory]);

  return (
    <div ref={tabsRef} className="category-tabs">
      {categories.map((category) => (
        <button
          key={category.id}
          ref={category.id === activeCategory ? activeTabRef : null}
          onClick={() => onCategoryChange(category.id)}
          className={`category-tab ${
            activeCategory === category.id ? "active-tab" : ""
          }`}
        >
          {category.label}
        </button>
      ))}
    </div>
  );
}
