import React from "react";
import styles from "../FundingRelease/index.module.css";
import Container from "../../Components/General/Container";
import { assetPath } from "../../assets/s3Uploads";

const EsopsBuyBackRelease = () => {
  return (
    <Container>
      <div className={styles.fundingContainer}>
        <div className={styles.fundingHeader}>
          NowPurchase completed its First-Ever ESOP Buyback
        </div>
        <div className={styles.iconContainer}>
          <span className={styles.info}>
            <i class="ri-calendar-line"></i> November 21
          </span>
          <span className={styles.info}>
            <i class="ri-map-pin-line"></i> Kolkata
          </span>
        </div>
        <img
          className={styles.fundingImg}
          src={assetPath.EsopsBuyBackMainImg}
          alt="Press Release"
        />
        <div className={styles.description}>
          <strong>
            Kolkata, November 21, 2024 -
            <a
              href="https://nowpurchase.com/"
              target="blank"
              rel="noopener noreferrer"
            >
              {" "}
              NowPurchase
            </a>
            ,
          </strong>{" "}
          a B2B startup announced that it has completed its first-ever{" "}
          <b>Employee Stock Option Plan (ESOP) buyback</b>, marking a key
          milestone for the company and the startup ecosystem in Kolkata. The
          buyback, which was valued at approximately{" "}
          <b>100 times the initial purchase price</b>, has had a transformative
          impact on employees' lives.
        </div>
        <div className={styles.description}>
          The <b>ESOP</b> buyback exemplifies NowPurchase’s commitment to
          building a world class team that reaps benefits of their hard work &
          are key shareholders of their company.The company recently secured $6
          million in funding, raising its total funding to over $10 million.
          This round saw equity and debt investments from{" "}
          <b>InfoEdge Ventures</b>, <b>Orios Venture Partner</b>,{" "}
          <b>100 Unicorns</b>, <b>VC Grid</b> and a number of family offices and
          angel investors, all of whom endorsed the initiative.
        </div>
        <div className={styles.description}>
          Participating team members shared their future plans in a{" "}
          <a href="https://www.youtube.com/embed/WlKdELXeqvw">video</a>,
          expressing aspirations to invest in financial markets, purchase their
          first car, or take steps toward homeownership. Several senior leaders
          like Sachin Singh, Prateek Losalka, and Nishant Singh,chose not to
          sell a good majority of their options, demonstrating their steadfast
          belief in the company’s vision.
        </div>
        <div className={`${styles.description} ${styles.leftBorderIndicator}`}>
          <div className={styles.italicsText}>
            "It brings me immense joy that our ESOP program, launched on July
            1st, 2019, has now enabled team members to benefit from our shared
            success. The fact that this buyback included employees across all
            functions —warehouse staff, potential CXOs, and everyone in
            between—highlights our commitment to growth and value creation for
            all. This wouldn’t have been possible without the support of our
            first institutional investors Orios & InfoEdge”
          </div>
          <b> - Naman Shah, Founder & CEO, NowPurchase</b>
        </div>
        <div className={`${styles.description} ${styles.leftBorderIndicator}`}>
          <div className={styles.italicsText}>
            “ESOPs have always been critical to both Naman and me. We have spent
            significant time making the team understand the value of ESOPs as
            part of our mission to uplift the nascent startup ecosystem in
            Kolkata. This buyback is a tangible culmination of the team reaping
            the rewards for their efforts. On the flip side, they have heavy
            skin in the game, and most have chosen to hold back their shares for
            even further growth.”
          </div>
          <b> - Aakash Shah, Co-Founder, NowPurchase</b>
        </div>
        <div className={styles.footer}>
          <strong>About NowPurchase:</strong>
          <div className={styles.description}>
            NowPurchase is a procurement solution where Metal Manufacturers can
            procure high quality raw materials like metal scrap, alloys and
            additives in a trusted & transparent manner. They have taken a novel
            approach with its own network of scrap processing centers & private
            labels for additives & alloys. It also provides its users with a
            WhatsApp bot to discover prices and stock in real time, a team to
            provide on-ground service and quality assurance, and a proprietary
            SaaS platform{" "}
            <a
              href="app.nowpurchase.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              MetalCloud
            </a>{" "}
            to optimize their manufacturing process NowPurchase was founded in
            2017 by Naman Shah and Aakash Shah, with an initial investment of
            $300K from Nipha Group. The company draws on Naman's experience with
            multiple startups in the US and Singapore, where he witnessed
            firsthand the transformative power of technology for businesses.
            Aakash’s decade plus manufacturing experience has helped the company
            with its supply chain & building the right solutions for metal
            manufacturers. With a comprehensive range of services and solutions,
            NowPurchase aims to expand its geographic footprint and become the
            leading procurement platform for all metal manufacturers. Ankan
            Adhikari joined the core team in 2019 as a CTO. Ankan is a serial
            entrepreneur whose previous EdTech venture was acquired by UpGrad.
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EsopsBuyBackRelease;
