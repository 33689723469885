import React, { useState } from "react";
import { Modal, Form, Button, SelectPicker, Input, Checkbox } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import CheckIcon from "@rsuite/icons/Check";

export const mentionedUserId = "U06P3BX7E0K"; //slack id of Sreeparna

const ProductEnquiry = ({ enquiredProduct, modalOpen, setModalOpen }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [enquiryPurpose, setEnquiryPurpose] = useState(enquiredProduct?.title);
  const [remarks, setRemarks] = useState("");

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    // Validate form fields
    const namePattern = /^[a-zA-Z\s]*$/;
    const phonePattern = /^\d+$/;

    if (!name || !namePattern.test(name)) {
      alert(
        "Please enter a valid name. Only alphabets and spaces are allowed."
      );
      return;
    }

    if (!phone || !phonePattern.test(phone)) {
      alert("Please enter a valid phone number. Only numbers are allowed.");
      return;
    }

    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbxZLGeDKBXdrvKWfvW5XuieHzGmAIRDGNd2s2FN2YMf3T-QHuvgy9Ml-5SxXhtYJXaI/exec?function=updateData",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            contactNumber: phone,
            enquiryPurpose: enquiredProduct?.title,
            remarks,
          }),
        }
      );

      setModalOpen(false);
      await sendSlackNotification({
        name,
        phone,
        enquiredProduct: enquiredProduct?.title,
        remarks,
      });
    } catch (error) {
      alert("Error submitting enquiry");
    } finally {
      // Reset form fields
      setName("");
      setPhone("");
      setEnquiryPurpose("");
      setRemarks("");
    }
  };

  const sendSlackNotification = async ({
    name,
    phone,
    enquiredProduct,
    remarks,
  }) => {
    const slackWebhookUrl =
      "https://hooks.slack.com/services/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";

    // Constructing the Slack message with blocks for enhanced formatting
    const payload = {
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*New Enquiry Received*`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `<@${mentionedUserId}> You have a new enquiry:`,
          },
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Name:*\n${name}`,
            },
            {
              type: "mrkdwn",
              text: `*Contact Number:*\n${phone}`,
            },
          ],
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*Enquiry Purpose:*\n${
              enquiredProduct || "No product specified"
            }`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*Remarks:*\n${remarks || "No remarks provided"}`,
          },
        },
      ],
    };

    try {
      // Sending the formatted message to Slack
      await fetch(slackWebhookUrl, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      console.log("Slack notification sent successfully");
    } catch (error) {
      console.error("Error sending Slack notification:", error);
    }
  };

  console.log(enquiredProduct?.title);

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title> Enquiry Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid onSubmit={handleSubmit}>
          <Form.Group>
            <Form.ControlLabel for="name">
              <span>Name</span>
            </Form.ControlLabel>
            <Form.Control
              name="name"
              type="text"
              placeholder="Enter your name here"
              Required
              value={name}
              onChange={(value) => setName(value)}
            />
            {/* <Form.HelpText>Required</Form.HelpText> */}
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel for="phone">
              <span>Contact Number</span>
            </Form.ControlLabel>
            <Form.Control
              name="phone"
              type="tel"
              placeholder="Enter your contact number"
              value={phone}
              onChange={(value) => setPhone(value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel for="entry_purpose">
              <span>Enquiry Purpose</span>
            </Form.ControlLabel>
            <Form.Control
              name="enquiry-purpose"
              placeholder="Enter your Enquiry Purpose"
              value={enquiredProduct?.title}
              readOnly
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel for="remark">
              <span>Remark</span>
            </Form.ControlLabel>
            <Input
              value={remarks}
              onChange={(value) => setRemarks(value)}
              as="textarea"
              name="remark"
              rows={7}
              placeholder="Enter your remarks here"
            />
          </Form.Group>

          <Form.Group style={{ float: "right" }}>
            <Button type="submit">
              <CheckIcon /> Submit Enquiry
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProductEnquiry;
