import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAZHtVWcPHAblhCngnRJoQnybp8VYOY0dM",
  authDomain: "landing-page-np.firebaseapp.com",
  projectId: "landing-page-np",
  storageBucket: "landing-page-np.firebasestorage.app",
  messagingSenderId: "956939621520",
  appId: "1:956939621520:web:410a3061534722464cb415",
  measurementId: "G-F3Y7RM5BML",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
