import React from "react";
import "./styles.css";

export function ProductCard({ product, onEnquire }) {
  return (
    <div className="product-card">
      <div className="product-card-image">
        <img src={product.imageUrl} alt={product.title} />
      </div>
      <div className="product-card-content">
        <h3>{product.title}</h3>
        <p>{product.description}</p>
        <button onClick={() => onEnquire(product)} className="enquire-button">
          Enquire Now <i class="ri-arrow-right-line"></i>
        </button>
      </div>
    </div>
  );
}
