import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { ProductCard } from "./ProductCard";
import { ProductSkeleton } from "./ProductSkeleton";
import "./styles.css";

export default function ProductGrid({ activeCategory, onEnquire }) {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAllProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Filter products based on active category
  const filteredProducts = allProducts.filter(
    (product) => product.category === activeCategory
  );

  if (loading) {
    return (
      <div className="product-grid">
        {[1, 2, 3].map((skeleton) => (
          <ProductSkeleton key={skeleton} />
        ))}
      </div>
    );
  }

  if (filteredProducts.length === 0) {
    return (
      <div className="no-products-found">
        <img
          src="/no-products.svg"
          alt="No products found"
          className="no-products-image"
        />
        <h3>No Products Found</h3>
        <p>We couldn't find any products in this category.</p>
      </div>
    );
  }

  return (
    <div className="product-grid">
      {filteredProducts.map((product) => (
        <ProductCard key={product.id} product={product} onEnquire={onEnquire} />
      ))}
    </div>
  );
}
