import React from "react";
import "./index.css";
const Strip = () => {
  return (
    <>
      <div className="stripheader">
        <p className="stripP">
          <img src="https://a.slack-edge.com/production-standard-emoji-assets/14.0/google-large/1f389@2x.png" />
          <strong>NowPurchase completed its First-Ever ESOP Buyback</strong>
          <a
            href="https://nowpurchase.com/NowPurchase-completed-its-First-Ever-ESOP-Buyback"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Now
          </a>
          <img src="https://a.slack-edge.com/production-standard-emoji-assets/14.0/google-large/1f389@2x.png" />
        </p>
      </div>
    </>
  );
};

export default Strip;
